.ImageSelection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ImageSelection-title {
  font-size: calc(10px + 3vmin);
  font-weight: bolder;
  margin-bottom: 5vmin;
}

.ImageSelection-images {
  display: flex;
  flex-direction: row;
}

.ImageSelection-image {
  width: 30vmin;
  height: 30vmin;
  border-radius: 50%;
  border: solid;
  border-color: rgb(145, 180, 245);
  border-width: 5px;
  margin: 5px;
  cursor : pointer;
}

.ImageSelection-uploader {
  display: none;
}

.ImageSelection-preview {
  position: absolute;
  top: calc(50% - 15vmin);
  left: calc(50% - 15vmin);
  width: 30vmin;
  height: 30vmin;
  border-radius: 50%;
  border: solid;
  border-color: rgb(145, 180, 245);
  border-width: 5px;
  object-fit: cover;
}
