.ImageHighlight-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.ImageHighlight-rank {
  position: absolute;
  top: calc(50% + 18vmin);
  width: 100%;
  font-weight: bolder;
  z-index: 101;
}

.ImageHighlight-distance {
  position: absolute;
  top: calc(50% + 22vmin);
  width: 100%;
  font-weight: bolder;
  z-index: 101;
}

.ImageHighlight-close {
  font-size: 3vmin;
  position: absolute;
  right: 2vw;
  bottom: 6vh;
  width: 10vmin;
  height: 10vmin;
  line-height: 10vmin;
  color: rgb(60, 133, 244);
  background-color: white;
  border-radius: 50%;
  cursor : pointer;
  z-index: 101;
}
