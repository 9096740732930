.NewsResult {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 0;
}

.NewsResult-title {
  position: absolute;
  top: 5vmin;
  font-size: 5vmin;
  font-weight: bolder;
}

.NewsResult-query-latency {
  position: absolute;
  top: 11vmin;
  font-size: 4vmin;
}

.NewsResult-neighbors {
  position: absolute;
  top: 18vmin;
  left: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  height: 70%;
  color: rgb(60, 133, 244);
}

.NewsResult-neighbor-text {
  width: 18%;
  height: 48%;
  border-radius: 5%;
  border: solid;
  border-color: rgb(145, 180, 245);
  border-width: 2px;
  margin: calc(1% - 2px);
  display: flex;
  justify-content: center;
  overflow: scroll;
  background-color: white;
  transition: all 0.5s 0s ease;
  text-decoration: none;
}

.NewsResult-neighbor-text:hover {
  text-decoration: underline;
}

.NewsResult-neighbor-text:link {
  color: inherit;
}

.NewsResult-neighbor-text:visited {
  color: inherit;
}

.NewsResult-neighbor-text div {
  width: 100%;
  height: 100%;
  padding: 5px;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: lighten;
  background-repeat: no-repeat;
  background-position: center;
}
