.ModeSelection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ModeSelection-title {
  font-size: calc(10px + 3vmin);
  font-weight: bolder;
  margin-bottom: 5vmin;
}

.ModeSelection-selectors {
  display: flex;
  flex-direction: row;
}

.ModeSelection-selectors > * + * {
  margin-left: 5vmin;
}

.ModeSelection-selector {
  display: flex;
  flex-direction: column;
}

.ModeSelection-selector img {
  width: 28vmin;
  height: 28vmin;
  border-radius: 50%;
  border: solid;
  border-color: rgb(145, 180, 245);
  border-width: 5px;
  margin: 5px;
  cursor : pointer;
  transition: all 0.5s 0s ease;
  margin: 2vmin;
}

.ModeSelection-selector img:hover {
  width: 32vmin;
  height: 32vmin;
  margin: 0vmin;
}

.ModeSelection-selector div {
  font-size: 3vmin;
  font-weight: bolder;
}
