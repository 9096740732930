.Intro {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Intro-title {
  font-size: calc(10px + 10vmin);
  font-weight: bolder;
}

.Intro-subtitle {
  font-size: calc(10px + 3vmin);
}

.Intro-repo {
  margin-top: 20vh;
  font-size: calc(10px + 2vmin);
}

.Intro-repo a {
  color: white;
  text-decoration: none;
}

Intro-repo a:visited {
  text-decoration: underline;
  color: inherit;
}

.Intro-start {
  width: 12vmin;
  height: 12vmin;
  border-radius: 50%;
  background-color: white;
  line-height: 12vmin;
  color: rgb(60, 133, 244);
  margin-top: 5vh;
  font-weight: bolder;
  animation: ripple 1.5s linear infinite;
  cursor : pointer;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0px rgba(255,255,255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(255,255,255, 0.0);
  }
}

.Intro-transit {
  position: absolute;
  border-radius: 50%;
  background-color: white;
  animation: wipe 0.8s ease-out;
}

@keyframes wipe {
  0% {
    width: 0vmax;
    height: 0vmax;
  }
  100% {
    width: 120vmax;
    height: 120vmax;
  }
}
